import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { FETCH_BANNER } from 'lib/graphql/queries';

import { Redirect } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { useSegment } from 'lib/hooks/useSegment';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useBrowserHistory } from 'lib/hooks/useBrowserHistory';
import { MAIL, PAGE_COUNT, PHONE_LINK, WEB_SITE } from 'lib/constants';
import { BackPressModal, HelpMeModal, Icon, PointOfSaleModal } from 'lib/components';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { APP_ENV } from 'config';
import { AppSwitcher, linkToFinder, linkToPatientPortal, linkToPracticePortal } from '@frontend/cherry-library';

const progressTheme = createTheme({
  palette: {
    primary: {
      main: '#1ec37c',
    },
    secondary: {
      main: '#c5cae9',
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

interface Props {
  pathname: string;
  pageIndex: number;
  showBackButton: boolean;
  backUrl: string;
  goBackCallback?: () => void;
  hideTitle?: boolean;
}

export interface BannerProps {
  showForPatient: boolean;
  patientText: string;
}

export const Header = ({ pathname, pageIndex, showBackButton, backUrl, goBackCallback, hideTitle = false }: Props) => {
  const { trackPage } = useSegment();
  const { t: translate } = useTranslation();
  const { navigate, navigateBack } = useNavigation();
  const client = useApolloClient();
  const { pageName, merchantSlug } = useBrowserHistory();
  const { organization, organizationGroup, isDemo, flowType, locations, authData } = useStore();
  const { sendActionSegmentEvent } = useSegmentContext();

  // Modal States
  const [helpMe, setHelpMe] = useState(false);
  const [securityModal, setSecurityModal] = useState(false);
  const [pointOfSale, setPointOfSale] = useState(false);
  const [banner, setBanner] = useState<BannerProps | null>(null);

  const goBackHandler = () => {
    if (backUrl) {
      return navigate(backUrl);
    }

    if (goBackCallback) {
      return goBackCallback();
    }

    navigateBack();
  };

  const getProgressValue = () => {
    if (pageIndex > 0) {
      return (100 / PAGE_COUNT) * pageIndex;
    }
    return 0;
  };

  const isProgressVisible =
    !isDemo &&
    APP_ENV !== 'sandbox' &&
    pathname !== '/' &&
    pathname !== `/${organization?.slug}/result` &&
    pathname !== `/${organization?.slug}/approval` &&
    pathname !== `/${organization?.slug}/expired` &&
    pathname !== `/${organization?.slug}/checkout-in-person` &&
    pathname !== `/${organization?.slug}/checking-out` &&
    pathname !== `/${organization?.slug}/book-an-appointment` &&
    pathname !== `/${organization?.slug}/preview-my-payments` &&
    pathname !== `/${organization?.slug}/plaid-paystubs-complete` &&
    pathname !== `/${organization?.slug}/autopay` &&
    pathname !== `/${organization?.slug}/application-review` &&
    pathname !== `/${organization?.slug}/success` &&
    pathname !== `/${organization?.slug}/choose-plan` &&
    pathname !== `/${organization?.slug}/plans` &&
    pathname !== `/${organization?.slug}/plan-review` &&
    pathname !== `/${organization?.slug}/down-payment` &&
    pathname !== `/${organization?.slug}/add-card` &&
    pathname !== `/${organization?.slug}/method-list` &&
    pathname !== `/${organization?.slug}/confirm` &&
    pathname !== `/${organization?.slug}/ineligible` &&
    pathname !== `/${organization?.slug}/faq`;

  const backButtonHandlerScreens = [
    'checkout-in-person',
    'update-location',
    'faq',
    'plans',
    'customize-plan',
    'plan-review',
    'add-card',
    'add-remote-check',
    'remote-check-review',
    'down-payment',
    'method-list',
    'autopay',
    'confirm',
    'address-info',
    'verify',
    'choose-plan',
    'request-increase',
    'checking-out',
    'book-an-appointment',
    'preview-my-payments',
    'additional-info',
    'approval-boost',
    'approval-boost/additional-info',
    'request-amount',
    'preview-payment',
  ];

  const hidePointOfSaleFlowHeaderModalScreens = ['success', 'pos-congrats'];

  useEffect(() => {
    getBanner();

    if (!backButtonHandlerScreens.includes(document?.location?.pathname?.split('/')[2])) {
      window?.history?.pushState(null, window?.location?.pathname);
      window?.addEventListener('popstate', onBackButtonEvent);

      return () => {
        window?.removeEventListener('popstate', onBackButtonEvent);
      };
    }
  }, []);

  const getBanner = async () => {
    const { data } = await client.query({
      query: FETCH_BANNER,
      variables: {},
    });

    if (data?.fetchBanner?.length > 0) {
      setBanner(data?.fetchBanner?.[0]);
    }
  };

  const helpMeToggle = () => {
    const segmentName =
      locations?.length > 1
        ? WPQSegmentNames.multiLocationGetHelpButtonClicked
        : WPQSegmentNames.singleLocationGetHelpButtonClicked;
    sendActionSegmentEvent(segmentName, {
      pageName: pageName || '',
      merchantSlug: merchantSlug || '',
    });
    !helpMe && sendActionSegmentEvent(WPQSegmentNames.helpModalLoad);

    setHelpMe(!helpMe);
  };

  const closeHelpMe = () => {
    setHelpMe(false);
  };

  const pointOfSaleModalToggle = () => {
    setPointOfSale(!pointOfSale);
  };

  const closePointOfSaleModal = () => {
    setPointOfSale(false);
  };

  const onEmailPressed = (e) => {
    sendActionSegmentEvent(WPQSegmentNames.helpModalEmailClicked, {
      supportType: 'email',
      pageName: pageName || '',
      merchantSlug: merchantSlug || '',
    });

    Redirect(MAIL, '_self');
    e.preventDefault();
  };

  const onCallPressed = (e) => {
    sendActionSegmentEvent(WPQSegmentNames.helpModalCallClicked, {
      supportType: 'phone',
      pageName: pageName || '',
      merchantSlug: merchantSlug || '',
    });

    Redirect(PHONE_LINK, '_self');
    e.preventDefault();
  };

  const logoutPressed = () => {
    StorageService.clearUserData();
    navigate('');
  };

  const openSecurityModal = () => {
    setSecurityModal(true);
  };

  const closeSecurityModal = () => {
    setSecurityModal(false);
  };

  const yesCancelApplicationPressed = () => {
    navigate('pos-payment-review');
  };

  const onBackButtonEvent = () => {
    window?.history?.pushState(null, window?.location?.pathname);
    openSecurityModal();
  };

  const showAppSwitcher = pathname === `/${organization?.slug}`;
  const apps = [
    {
      trackId: 'patient-portal',
      label: translate('appswitcher.patientPortal'),
      url: linkToPatientPortal(),
    },
    {
      trackId: 'practice-portal',
      label: translate('appswitcher.practicePortal'),
      url: linkToPracticePortal(),
    },
    {
      trackId: 'practice-finder',
      label: translate('appswitcher.practiceFinder'),
      url: linkToFinder(),
    },
    {
      trackId: 'cherry-website',
      label: translate('appswitcher.cherryWebsite'),
      url: `${WEB_SITE}/patients`,
    },
  ];

  return (
    <Container>
      <InnerContainer>
        <HeaderContainer>
          <IconContainer>
            {showBackButton && (
              <IconButton aria-label="back" color="inherit" onClick={goBackHandler}>
                <BackIcon src={'/icon/short_left.svg'} />
              </IconButton>
            )}
            {showAppSwitcher && (
              <AppSwitcher apps={apps} showName={false} headerTitle={translate('appswitcher.cherryWebsite')} />
            )}
          </IconContainer>
          {!hideTitle ? (
            <TitleContainer>
              <Icon width={16} height={16} src={'cherry-logo'} />
              <Title>{translate('header.title')}</Title>
              <HeaderTitle>{organization?.name || organizationGroup?.name}</HeaderTitle>
            </TitleContainer>
          ) : null}

          {flowType === 'PointOfSalesFlow' &&
          !hidePointOfSaleFlowHeaderModalScreens.includes(document?.location?.pathname?.split('/')[2]) ? (
            <IconContainer onClick={pointOfSaleModalToggle}>
              <HelpIcon src={'/icon/close.svg'} />
            </IconContainer>
          ) : (
            <IconContainer onClick={helpMeToggle}>
              <HelpIcon src={'/icon/help_circle.svg'} />
            </IconContainer>
          )}
        </HeaderContainer>
        <HelpMeModal
          show={helpMe}
          hideModal={closeHelpMe}
          emailPressed={onEmailPressed}
          callPressed={onCallPressed}
          logoutPressed={logoutPressed}
          banner={banner}
          title={translate('Need Help?')}
        />

        <PointOfSaleModal
          show={pointOfSale}
          hideModal={closePointOfSaleModal}
          yesCancelApplicationPressed={yesCancelApplicationPressed}
        />

        <BackPressModal show={securityModal} hideModal={closeSecurityModal} logoutPressed={logoutPressed} />
      </InnerContainer>
      {isDemo || APP_ENV === 'sandbox' ? (
        <DemoModeContainer>
          <Icon width={20} height={20} src={'warning_outline'} />
          <DemoModeText
            dangerouslySetInnerHTML={{
              __html: translate('header.demoMode'),
            }}
          />
        </DemoModeContainer>
      ) : null}
      <ProgressContainer>
        {isProgressVisible && (
          <ThemeProvider theme={progressTheme}>
            <ProgressInnerContainer>
              <LinearProgress variant="determinate" value={getProgressValue()} />
            </ProgressInnerContainer>
          </ThemeProvider>
        )}
      </ProgressContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  z-index: 100;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.main.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: inset 0px -1px 0px #dadada;
  width: 100%;
  height: 80px;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: 70px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 48px));
`;

const ProgressContainer = styled.div``;

const DemoModeContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.main.demoModeColor};
  padding: ${(props) => props.theme.constants.s} ${(props) => props.theme.constants.l};
`;

const DemoModeText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.main.grayColor};
`;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: ${(props) => props.theme.main.midnightBlue};

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const IconContainer = styled.div`
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressInnerContainer = styled.div`
  width: 100%;
`;

const HelpIcon = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 25px;
    height: 25px;
  }
`;

const BackIcon = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 23px;
    height: 23px;
  }
`;
