import { useSentry } from './useSentry';

import client from 'lib/graphql/client';
import { IVR_TOKEN_EXCHANGE } from 'lib/graphql/mutations/IVRTokenExchange';
import { initChatWidgetYellowAI, updateChatWidgetYellowAI } from 'lib/utils';

export const useInitYellowAIChatbot = () => {
  const { captureException } = useSentry();

  async function fetchIVRToken() {
    let token: string | undefined;
    try {
      const response = await client.mutate({
        mutation: IVR_TOKEN_EXCHANGE,
      });

      token = response?.data?.ivrTokenExchange?.ivrToken ?? null;
    } catch (error) {
      captureException(error, { errorContext: 'fetchIVRToken' });
    }

    return token;
  }

  async function setChatbot(borrower) {
    try {
      const { id, phone, firstName } = borrower;
      let borrowerDetails;

      if (firstName) {
        const borrowerIVRToken = await fetchIVRToken();
        if (borrowerIVRToken) {
          borrowerDetails = {
            id,
            phone,
            name: firstName,
            borrowerIVRToken,
          };
          updateChatWidgetYellowAI(borrowerDetails);
        } else {
          captureException('setChatbot - IVRToken is null. Setting up unauthenticated bot', { borrower });
        }
      }

      if (!borrowerDetails) {
        initChatWidgetYellowAI();
      }
    } catch (error) {
      captureException(error, { errorContext: 'setChatbot' });
    }
  }

  return { setChatbot };
};
