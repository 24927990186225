import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ButtonContainer, ContentContainer, FooterText } from 'lib/components/Common';
import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { IncorrectInfoModal } from '../IncorrectInfoModal';
import { PATCH_APPLY_WITH_PATIENT_CONSENT } from 'lib/graphql/mutations';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import StorageService from 'lib/services/Storage';
import { useApplyWithPatientReview } from '../useApplyWithPatientReview';
import { ApplyWithPatientPageSteps, ReviewProps } from '../type';
import dayjs from 'dayjs';
import { DATE_FORMAT, isOrganizationCompetitivePlastics, isOrganizationDental } from 'lib/constants';
import { FormatPhoneNumber, FormatPrice } from 'lib/utils';
import { theme } from 'config/theme';
import { GET_ORGANIZATION_REGULATIONS } from 'lib/graphql/queries';
import client from 'lib/graphql/client';

const ApplyWithPatientReview = ({ setApplyWithPatientStep }: ReviewProps) => {
  const { t: translate } = useTranslation();
  const { applyWithPatientReviewData, organization, authData, setOrganizationRegulations } = useStore();
  const { slug } = authData || {};
  const { footerText } = useApplyWithPatientReview();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [patchApplyWithPatientConsent] = useMutation(PATCH_APPLY_WITH_PATIENT_CONSENT);

  const restartApplicationWithPractice = async () => {
    // update apply with patient status => DECLINED
    const resp = await updateApplyWithPatientStatus(false);
    if (resp?.id) {
      setApplyWithPatientStep('cancel');
    } else {
      setApplyWithPatientStep('error');
    }
  };

  const getRegulations = async () => {
    const response = await client.query({
      query: GET_ORGANIZATION_REGULATIONS,
      variables: {
        input: {
          organizationId: organization?.id,
          borrowerId: applyWithPatientReviewData?.borrowerId,
        },
      },
    });
    setOrganizationRegulations(response?.data?.getRegulationsConfig);
  };

  useEffect(() => {
    getRegulations?.();
  }, []);

  const restartApplicationOnMobile = async () => {
    // update apply with patient status => DECLINED
    const resp = await updateApplyWithPatientStatus(false);
    if (resp?.id) {
      navigate(`/${slug}/`);
    } else {
      setApplyWithPatientStep('error');
    }
  };

  const handleAgree = async () => {
    // update apply with patient status => APPROVED
    const resp = await updateApplyWithPatientStatus(true);
    if (resp?.id) {
      setApplyWithPatientStep('submit');
    } else {
      setApplyWithPatientStep('error');
    }
  };

  const handleStartOver = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  async function updateApplyWithPatientStatus(approve: boolean) {
    try {
      const { data } = await patchApplyWithPatientConsent({
        variables: {
          input: {
            status: approve ? 'APPROVED' : 'DENIED',
            id: applyWithPatientReviewData?.id,
          },
        },
      });

      return data?.patchApplyWithPatientConsent;
    } catch (err) {}
  }

  return (
    <Container showBackButton={false} hideTitle={false}>
      <IncorrectInfoModal
        restartWithPractice={restartApplicationWithPractice}
        restartOnMobile={restartApplicationOnMobile}
        show={showModal}
        hideModal={closeModal}
      />
      <ContentContainer>
        <Icon src={'glasses-review'} />
        <Title m={'10px 0 0 0'}>{translate('applyWithPatientReview.title')}</Title>
        <Subtitle m={'8px 0px 24px 0px'}>{translate('applyWithPatientReview.subtitle')}</Subtitle>
        <ReviewContainer>
          <ReviewDetailContainer>
            <ReviewSection isBorderAvailable={true}>
              <ReviewTitle>{translate('applyWithPatientReview.infoMap.title1')}</ReviewTitle>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.firstname')}: {applyWithPatientReviewData?.firstName}
              </ReviewText>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.lastname')}: {applyWithPatientReviewData?.lastName}
              </ReviewText>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.birthday')}:{' '}
                {dayjs(applyWithPatientReviewData?.dob).format(DATE_FORMAT)}
              </ReviewText>
              {applyWithPatientReviewData?.email && (
                <ReviewText>
                  {translate('applyWithPatientReview.infoMap.email')}: {applyWithPatientReviewData?.email}
                </ReviewText>
              )}
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.phone')}:{' '}
                {FormatPhoneNumber(applyWithPatientReviewData?.phone)}
              </ReviewText>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.ssn')}: {applyWithPatientReviewData?.last4ssn}
              </ReviewText>
            </ReviewSection>
            <ReviewSection isBorderAvailable={applyWithPatientReviewData?.statedIncome ? true : false}>
              <ReviewTitle>{translate('applyWithPatientReview.infoMap.title2')}</ReviewTitle>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.address')}:{' '}
                {applyWithPatientReviewData?.address?.streetAddress}
              </ReviewText>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.apt')}:{' '}
                {applyWithPatientReviewData?.address?.aptUnitAddress || 'N.A.'}
              </ReviewText>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.city')}: {applyWithPatientReviewData?.address?.city}
              </ReviewText>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.state')}: {applyWithPatientReviewData?.address?.state}
              </ReviewText>
              <ReviewText>
                {translate('applyWithPatientReview.infoMap.zip')}: {applyWithPatientReviewData?.address?.zip}
              </ReviewText>
            </ReviewSection>
            {applyWithPatientReviewData?.statedIncome && (
              <ReviewSection isBorderAvailable={false}>
                <ReviewTitle>{translate('applyWithPatientReview.infoMap.title3')}</ReviewTitle>
                <ReviewText>
                  {translate('applyWithPatientReview.infoMap.income', {
                    type:
                      isOrganizationDental(organization?.industry) ||
                      isOrganizationCompetitivePlastics(organization?.industry)
                        ? translate('applyWithPatientReview.infoMap.household')
                        : translate('applyWithPatientReview.infoMap.individual'),
                  })}
                  : {`$${FormatPrice(applyWithPatientReviewData?.statedIncome)}`}
                </ReviewText>
                <ReviewText>
                  {translate('applyWithPatientReview.infoMap.housing')}:{' '}
                  {`$${FormatPrice(applyWithPatientReviewData?.housingPayment)}`}
                </ReviewText>
                <ReviewText>
                  {translate('applyWithPatientReview.infoMap.estimation')}:{' '}
                  {`$${FormatPrice(applyWithPatientReviewData?.requestedAmount)}`}
                </ReviewText>
              </ReviewSection>
            )}
          </ReviewDetailContainer>
        </ReviewContainer>
      </ContentContainer>
      <StyledButtonContainer>
        <FooterText dangerouslySetInnerHTML={{ __html: footerText }} />
        <ButtonWrapper>
          <Button ref={buttonRef} onClick={handleAgree}>
            {translate('applyWithPatientReview.agreeButton')}
          </Button>
          <Button secondary onClick={handleStartOver}>
            {translate('applyWithPatientReview.disagreeButton')}
          </Button>
        </ButtonWrapper>
      </StyledButtonContainer>
    </Container>
  );
};

export default ApplyWithPatientReview;

const StyledButtonContainer = styled(ButtonContainer)`
  padding: 0 24px 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  strong {
    color: ${(props) => props.theme.main.green};
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewContainer = styled.div`
  background-color: ${(props) => props.theme.main.darkGrayColor};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 11px;
`;

const ReviewTitle = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  font-weight: 700;
  margin-bottom: 8px;
`;

const ReviewDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ReviewText = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  font-weight: 400;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ReviewSection = styled.div<{ isBorderAvailable?: boolean }>`
  padding-bottom: ${(props) => (props?.isBorderAvailable ? '16px' : 0)};
  border-bottom: ${(props) => (props?.isBorderAvailable ? `1px solid ${theme.main.midnightBlue20}` : null)};
`;
