import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Rox from 'rox-browser';
import styled from 'styled-components';
import { useAlert } from 'react-alert';
import OtpInput from 'react18-input-otp';

import { START_OTP_FLOW, SUBMIT_OTP, UPDATE_BORROWER } from 'lib/graphql/mutations';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { FormatPhoneNumber } from 'lib/utils';
import { useSentry } from 'lib/hooks/useSentry';
import { useSegment } from 'lib/hooks/useSegment';
import { LoanStatuses } from 'pages/AuthChecker/type';
import { useNavigation } from 'lib/hooks/useNavigation';
import { WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useServices } from 'pages/AuthChecker/services';

import { Button, Container, DidntGetCodeModal, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { loanStatusService } from 'pages/AuthChecker/utilityFunctions';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { APP_ENV } from 'config';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';

const inputStyle = {
  width: '54px',
  height: '90px',
  fontSize: '20px',
  borderRadius: '4px',
  border: `1px solid ${theme.main.grayColor}`,
};

const containerStyle = {
  width: '100%',
  maxWidth: '400px',
  justifyContent: 'space-between',
};

const focusStyle = {
  outline: 0,
};

enum OtpReceiveChoiceEnum {
  SMS = 'SMS',
  VOICE = 'VOICE',
}

const Otp = () => {
  const alert = useAlert();
  const { captureException, setTag } = useSentry();
  const { createApplication } = useServices();
  const { trackEvent, applicationStatusUpdated } = useAnalytics();
  const { trackIdentify, trackAlias, trackSegmentEvent } = useSegment();
  const { flags } = useFlags();

  const {
    isDemo,
    setPageIndex,
    sessionData,
    organization,
    setBorrower,
    flowType,
    loan,
    slug,
    setAppLoading,
    setDefaultLanguage,
    defaultLanguage,
    setAuthData,
    sessionApiData,
    setSessionApiData,
  } = useStore();
  const { navigate } = useNavigation();
  const { startCheckApplicationStatus } = useSSEContext();
  const { getLoan, getApplication, getBorrower } = useServices();
  const { t: translate } = useTranslation();
  const [updateBorrower] = useMutation(UPDATE_BORROWER);

  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const [submitOtp] = useMutation(SUBMIT_OTP);
  const [startOtpFlow, { loading: resendLogin }] = useMutation(START_OTP_FLOW);
  const loadSegmentController = useRef(true);

  const otpDefaultValue = isDemo || APP_ENV === 'sandbox' ? '1111' : '';

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState<string>(otpDefaultValue);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.otpLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(3);
  }, [setPageIndex]);

  useEffect(() => {
    if (otp?.length === 4) {
      sendActionSegmentEvent(WPQSegmentNames.otpInputFilled, {
        otp: otp,
      });
      onSubmit?.();
    }
  }, [otp]);

  const closeModal = () => setShow(false);
  const openModal = () => {
    sendActionSegmentEvent(WPQSegmentNames.otpDidnotGetCodeClicked);
    setShow(true);
  };

  const runCheckoutWithEmailFlow = async (borrower) => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;

    const loanData = await getLoan(loanId);
    await getBorrower(borrower?.id);
    const applicationResponse = await getApplication(loanData?.applicationId);

    if (loanData?.status === LoanStatuses.AWAITING) {
      navigate(`/${slug}`);
      setAppLoading(false);
    } else if (loanData?.status === LoanStatuses.CANCELLED) {
      navigate(`/${slug}/expired`);
    } else {
      if (loanData?.selfCheckout) {
        applicationStatusUpdated(applicationResponse);
        loanStatusService(loanData, slug);
        setAppLoading(false);
      } else {
        startCheckApplicationStatus({});
        setAppLoading(false);
      }
    }

    navigate('choose-plan');
    return;
  };

  const onSubmit = async () => {
    try {
      if (!(loading || resendLogin)) {
        setLoading(true);
        trackEvent(AnalyticEventNames.CV_VERIFY);
        trackSegmentEvent(AnalyticEventNames.CV_VERIFY, {
          application: WEBPREQUAL,
        });
        sendActionSegmentEvent(WPQSegmentNames.otpContinueClicked);
        const { data } = await submitOtp({
          variables: { input: { code: otp, phone: sessionData?.phone } },
          context: {
            headers: {
              extra: 'borrowerInit',
            },
          },
        });
        if (data?.submitOtp?.success) {
          const { authorization, borrower } = data?.submitOtp;
          Rox.setCustomNumberProperty('borrower_id', Number(borrower?.id));
          Rox.setCustomStringProperty('rox.distinct_id', borrower?.id);
          Rox.setCustomNumberProperty('organization_id', Number(organization?.id));
          Rox.setCustomStringProperty('organization_id_str', organization?.id?.toString());
          setTag('phone', sessionData?.phone);
          setTag('organization', organization?.id);
          setTag('borrower', borrower?.id);
          setBorrower(borrower);

          setAuthData({
            slug: organization?.slug,
            loanToken: authorization?.token,
            token: '',
            refreshToken: authorization?.refreshToken,
          });

          if (borrower?.id && defaultLanguage) {
            const response = await updateBorrower({
              variables: {
                input: {
                  id: borrower?.id,
                  language: defaultLanguage?.toUpperCase(),
                  source: 'GREET',
                },
              },
            });
            setBorrower(response?.data?.updateBorrower?.data);
          }

          trackAlias(borrower?.id);
          trackIdentify({ ...sessionData, ...borrower });
          if (flowType === 'CheckoutWithEmailFlow' || flowType === 'CheckoutOnDeviceFlow') {
            await runCheckoutWithEmailFlow(borrower);
            return;
          }
          const newApprovalScreen = flags.SCREEN.newApproval.isEnabled() || false;
          const newApprovalCard = flags.SCREEN.newApprovalCard.isEnabled() || false;
          setSessionApiData({
            featureFlags: {
              ...sessionApiData?.featureFlags,
              newApprovalScreen,
              newApprovalCard,
            },
          });
          await createApplication();
        } else {
          setLoading(false);
          trackEvent(AnalyticEventNames.CV_WRONG_CODE);
          alert.info(translate('otp.codeDoesNotMatch'));
        }
      }
    } catch (err) {
      captureException(err, { message: 'Otp page submit error', session: sessionData });
      setLoading(false);
    }
  };

  const handleChange = (value: string): void => {
    setOtp(value);
  };

  const otpActions = () => {
    const { borrower } = useStore.getState() || {};
    trackEvent(AnalyticEventNames.CV_DIDNT_GET_CODE);
    trackSegmentEvent('OTP Not Received', {
      organizationSlug: organization?.slug,
      organizationName: organization?.name,
      application: WEBPREQUAL,
      borrowerId: borrower?.id,
    });
  };

  const resendOtp = useCallback(async () => {
    sendActionSegmentEvent(WPQSegmentNames.otpResendCodeClicked);
    otpActions();

    const { data } = await startOtpFlow({
      variables: {
        input: {
          phone: sessionData?.phone,
          otpChannel: OtpReceiveChoiceEnum.SMS,
        },
      },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });
    if (data?.startOtpFlow?.success) {
      alert.success(translate('otp.verificationCodeSent'));
    }
  }, [sessionData?.phone]);

  const callMeWithCode = useCallback(async () => {
    otpActions();
    sendActionSegmentEvent(WPQSegmentNames.otpCallWithClicked);

    const { data } = await startOtpFlow({
      variables: {
        input: {
          phone: sessionData?.phone,
          otpChannel: OtpReceiveChoiceEnum.VOICE,
        },
      },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });
    if (data?.startOtpFlow?.success) {
      alert.success(translate('otp.callingYouWithCode'));
    }
  }, [sessionData?.phone]);

  return (
    <Container showBackButton={true}>
      <ContentContainer>
        <Icon src={'message_writing'} />
        <Title data-testid="otp-title" m={'10px 0px 8px 0px'}>
          {translate('otp.weJustTextYou')}
        </Title>
        <Subtitle
          m={'0px 0px 40px 0px'}
          dangerouslySetInnerHTML={{
            __html: translate('otp.enterVerificationCode', {
              phone: FormatPhoneNumber(sessionData?.phone),
            }),
          }}
        />
        <OtpBox>
          <StyledOtpInput
            numInputs={4}
            isInputNum={true}
            inputStyle={inputStyle}
            containerStyle={containerStyle}
            focusStyle={focusStyle}
            shouldAutoFocus={true}
            value={otp}
            data-testid="otp"
            onChange={handleChange}
            placeholder={'#####'}
          />
        </OtpBox>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={otp.length !== 4 || loading || resendLogin}
          loading={loading || resendLogin}
          onClick={onSubmit}
        >
          {translate('buttons.continue')}
        </Button>
        <Button secondary={true} onClick={openModal}>
          {translate('buttons.didntGetACode')}
        </Button>
      </ButtonContainer>
      <DidntGetCodeModal show={show} hideModal={closeModal} reSendCode={resendOtp} callMeWithCode={callMeWithCode} />
    </Container>
  );
};

const OtpBox = styled.form`
  display: flex;
  justify-content: center;
`;

const StyledOtpInput = styled(OtpInput)`
  input {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #dadada;
    }

    :-ms-input-placeholder {
      color: #dadada;
    }
  }
`;

export default React.memo(Otp);
