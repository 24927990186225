import { useApolloClient, useMutation } from '@apollo/client';
import { useRef } from 'react';
import { useAlert } from 'react-alert';

import { BORROWER_INCOME_VERIFICATION } from 'lib/graphql/mutations';
import { GET_APPLICATION, GET_APPLICATION_FLOW, GET_BORROWER_INCOME_VERIFICATION } from 'lib/graphql/queries';
import { BorrowerIncomeVerificationSearch } from 'lib/graphql/searches';

import { APPLICATION_ALREADY_EXIST, POLL_FINISHED } from 'lib/constants';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import NavigateService from 'lib/services/Navigate';
import { INCOME_PROVIDER } from 'pages/ConnectBankAccount/ConnectBankAccount';
import { ApplicationFlowStatuses } from '../type';
import { getDefaultHighLineUrl, getIncomeVerificationRouteName } from 'lib/utils';
import { useNavigate } from 'react-router-dom';
import { IframeEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { useTranslation } from 'react-i18next';

export const useApplicationFlowService = () => {
  const alert = useAlert();
  const client = useApolloClient();
  const { navigate } = useNavigation();
  const nativeNavigate = useNavigate();
  const { applicationStatusUpdated } = useAnalytics();
  const { flags } = useFlags();
  const { t: translate } = useTranslation();

  const { type, organization, setApplication, setIncomeVerification, setApplicationFlow, setSessionApiData } =
    useStore();
  const { pushEvent } = useIframeEvents();

  const [borrowerIncomeVerification] = useMutation(BORROWER_INCOME_VERIFICATION);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const applicationRef = useRef<any>(null);

  let applicationUpdatedAtDate = '';

  const getApplicationFlow = async (applicationId: string) => {
    const { application } = useStore.getState() || {};
    const { data } = await client.query({
      query: GET_APPLICATION_FLOW,
      variables: {
        input: {
          applicationId: applicationId || applicationRef?.current?.id || application?.id,
        },
      },
    });

    if (data?.getApplicationFlow?.id) {
      setApplicationFlow(data?.getApplicationFlow);
    }
    return data?.getApplicationFlow;
  };

  const getApplication = async (applicationId) => {
    const { application } = useStore.getState() || {};

    const { data } = await client.query({
      query: GET_APPLICATION,
      variables: {
        input: {
          applicationId: applicationId || applicationRef?.current?.id || application?.id,
        },
      },
    });
    if (data?.getApplication?.id) {
      setApplication(data?.getApplication);
    }
    return data?.getApplication;
  };

  const getApplicationStatus = async (applicationId?: string, customTimeout = 2000) => {
    const resp = await getApplication(applicationId);
    await checkApplicationFlow(customTimeout, resp);
  };

  const pollApplicationStatus = async () => {
    await timeout(2000);
    await getApplicationStatus();
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getBorrowerIncomeVerificationWithSearch = async (borrowerId: string, applicationId: string) => {
    try {
      const {
        data: { borrowerIncomeVerificationsWithSearch },
      } = await client.query({
        query: GET_BORROWER_INCOME_VERIFICATION,
        variables: {
          input: {
            borrowerId,
            search: BorrowerIncomeVerificationSearch(borrowerId, applicationId),
          },
        },
      });

      const mxEnabled = flags.MX.isEnabled.isEnabled() || false;
      setSessionApiData({
        featureFlags: {
          mxEnabled,
        },
      });

      return borrowerIncomeVerificationsWithSearch;
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.error?.message === APPLICATION_ALREADY_EXIST) {
        await checkApplicationFlow();
      }
    }
  };

  const getBorrowerIncomeVerification = async (incomeType?: string) => {
    const { application, borrower, incomeVerification, sessionApiData } = useStore.getState() || {};
    const { featureFlags } = sessionApiData || {};

    try {
      const {
        data: { borrowerIncomeVerifications },
      } = await borrowerIncomeVerification({
        variables: {
          input: {
            borrowerId: borrower?.id,
            applicationId: application?.id,
            type: incomeType || incomeVerification.type,
            provider: featureFlags?.mxEnabled ? INCOME_PROVIDER.MX : INCOME_PROVIDER.PLAID,
          },
        },
      });

      return borrowerIncomeVerifications;
    } catch (err) {
      // err
    }
  };

  const checkApplicationFlow = async (customTimeout = 2000, applicationData?) => {
    const { application, isAlle, authData, sessionApiData } = useStore.getState() || {};
    const { featureFlags, isCognitoCompleted } = sessionApiData || {};
    const { slug } = authData || {};

    applicationRef.current = applicationData || application;
    const respStatus = await getApplicationFlow(application?.id);
    let startPooling = respStatus?.updatedAt !== applicationUpdatedAtDate;
    applicationUpdatedAtDate = respStatus?.updatedAt;

    if (type === 'SELF_CHECKOUT_OPTIONS') {
      NavigateService.navigate(`/${slug}/approval`);
      return;
    }
    if (respStatus?.status) {
      switch (respStatus?.status) {
        case ApplicationFlowStatuses.MISSING_INFO:
          if (!startPooling) {
            if (isAlle) {
              nativeNavigate(`/${slug}`);
            } else {
              navigate('info');
            }
          }
          break;
        case ApplicationFlowStatuses.AWAITING_APPROVAL_BOOST:
          if (!startPooling) {
            navigate('approval-boost');
          }
          break;
        case ApplicationFlowStatuses.PROCESSING:
          startPooling = true;
          break;
        case ApplicationFlowStatuses.ADDRESS_VERIFY_MINOR:
        case ApplicationFlowStatuses.ADDRESS_VERIFY_MAJOR:
          if (!startPooling) {
            navigate('address-verify');
          }
          break;
        case ApplicationFlowStatuses.SSN_REQUIRED_1:
          navigate('ssn-collect');
          break;
        case ApplicationFlowStatuses.SSN_REQUIRED_2:
          if (!startPooling) {
            navigate('ssn');
          }
          break;
        case ApplicationFlowStatuses.FULL_SSN_REQUIRED:
        case ApplicationFlowStatuses.FULL_SSN_REQUIRED_1:
        case ApplicationFlowStatuses.FULL_SSN_REQUIRED_2:
          if (!startPooling) {
            navigate('full-ssn-collect');
          }
          break;
        case ApplicationFlowStatuses.ITIN_NOT_SUPPORTED:
          if (!startPooling) {
            navigate('ssn-collect');
          }
          break;
        case ApplicationFlowStatuses.TREATMENT_QUESTIONS:
          if (!startPooling) {
            navigate('treatment-type');
          }
          break;
        case ApplicationFlowStatuses.MISSING_REQUESTED_AMOUNT:
          if (!startPooling) {
            navigate('request-amount');
          }
          break;
        case ApplicationFlowStatuses.MISSING_EMAIL:
          if (!startPooling) {
            navigate('email-info');
          }
          break;
        case ApplicationFlowStatuses.AWAITING_DEFAULT_HIGH_LINE:
          if (!startPooling) {
            const missings = respStatus?.missingInfos?.map((missing) => ({
              type: missing,
              value: null,
              url: getDefaultHighLineUrl(missing),
            }));
            setSessionApiData({ defaultHighLineMissings: missings });
            navigate(`default-line/${missings[0].url}`);
          }
          break;
        case ApplicationFlowStatuses.SSN_CONFLICT:
        case ApplicationFlowStatuses.ID_CONFLICT:
          startPooling = false;
          if (!startPooling) {
            navigate('result');
          }
          break;
        case ApplicationFlowStatuses.SSN_VERIFICATION:
          if (!startPooling) {
            navigate('ssn-verification');
          }
          break;
        case ApplicationFlowStatuses.FROZEN:
          startPooling = false;
          applicationStatusUpdated(applicationData);
          if (!startPooling) {
            NavigateService.navigate(`/${slug}/file-frozen`);
          }
          break;
        case ApplicationFlowStatuses.UNLICENSED:
          startPooling = false;
          applicationStatusUpdated(applicationData);
          if (!startPooling) {
            NavigateService.navigate(`/${slug}/ineligible`);
          }
          break;
        case ApplicationFlowStatuses.ID_REVIEW:
        case ApplicationFlowStatuses.EID_REVIEW:
          if (!startPooling) {
            navigate('id-review');
          }
          break;
        case ApplicationFlowStatuses.REVIEW:
          applicationStatusUpdated(applicationData);
          if (!startPooling) {
            navigate('application-review');
          }
          break;
        case ApplicationFlowStatuses.APPROVED:
          startPooling = false;
          pushEvent(IframeEventTypes.IFRAME, IframeEventActions.CLOSE);
          if (application?.id) {
            setApplication(application);
          }
          applicationStatusUpdated(applicationData);
          if (!startPooling) {
            NavigateService.navigate(`/${slug}/approval`);
          }
          break;
        case ApplicationFlowStatuses.COMPLETE_DEMO:
        case ApplicationFlowStatuses.DENIED:
        case ApplicationFlowStatuses.BLACKLIST:
          startPooling = false;
          if (application?.id) {
            setApplication(application);
          }
          applicationStatusUpdated(applicationData);
          if (!startPooling) {
            // if (respStatus?.ssnconflict) {
            //   navigate('ssn-conflict');
            // } else {
            NavigateService.navigate(`/${slug}/result`);
            // }
          }
          break;
        case ApplicationFlowStatuses.INCOME_VERIFICATION:
          startPooling = false;
          IvCheck(applicationRef?.current);

          const { incomeVerification } = useStore.getState() || {};
          const incomeVerificationSession = {
            ...incomeVerification,
            errorMessage: incomeVerification?.errorMessage?.split(','),
            providerMessage: incomeVerification?.providerMessage?.split(','),
          };
          if (isCognitoCompleted) {
            await pollApplicationStatus();
            break;
          } else if (
            incomeVerificationSession?.status &&
            incomeVerificationSession?.status === 'ERROR' &&
            incomeVerificationSession?.providerMessage?.includes('No_Valid_Account')
          ) {
            alert.info(translate('polling.error.noValidAccount'));
            startPooling = false;
            if (applicationRef?.current?.ivAllow?.paystub) {
              NavigateService.navigate(`/${slug}/upload-paystub/${applicationRef?.current?.id}`);
            } else {
              NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.current?.id}`);
            }
            break;
          } else if (
            incomeVerificationSession?.status &&
            !['COMPLETE', 'ERROR'].includes(incomeVerificationSession?.status)
          ) {
            NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.current?.id}`);
            break;
          } else if (
            incomeVerificationSession?.status === 'ERROR' &&
            incomeVerificationSession?.errorMessage?.includes('Retry') &&
            incomeVerificationSession?.providerMessage?.includes('Institution_Found') &&
            featureFlags?.mxEnabled
          ) {
            NavigateService.navigate(`/${organization?.slug}/connect-bank-account/${applicationRef?.current?.id}`);
            break;
          } else if (
            incomeVerificationSession?.status === 'ERROR' &&
            (incomeVerificationSession?.errorMessage?.includes('Institution_Not_Found') ||
              incomeVerificationSession?.errorMessage?.includes('Account_Not_Supported')) &&
            applicationRef?.current?.ivAllow?.paystub
          ) {
            startPooling = false;
            NavigateService.navigate(`/${slug}/upload-paystub/${applicationRef?.current?.id}`);
            break;
          } else if (
            incomeVerificationSession?.status === 'ERROR' &&
            incomeVerificationSession?.errorMessage?.includes('Retry') &&
            applicationRef?.current?.ivAllow?.paystub &&
            !featureFlags?.mxEnabled
          ) {
            NavigateService.navigate(`/${slug}/retry-plaid-connect/${applicationRef?.current?.id}`);
            break;
          } else if (incomeVerificationSession?.status && incomeVerificationSession?.status === 'ERROR') {
            startPooling = false;
            if (applicationRef?.current?.ivAllow?.paystub) {
              NavigateService.navigate(`/${slug}/upload-paystub/${applicationRef?.current?.id}`);
            } else {
              NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.current?.id}`);
            }
            break;
          } else {
            try {
              const incomeVerificationSearchRes = await getBorrowerIncomeVerificationWithSearch(
                applicationRef?.current?.borrowerId,
                applicationRef?.current?.id,
              );
              if (incomeVerificationSearchRes?.contents?.length > 0) {
                const incomeVerificationRes = incomeVerificationSearchRes?.contents?.[0];
                setIncomeVerification(incomeVerificationRes);

                const isRelatedIVWithApplication = incomeVerificationRes?.relatedApplications?.find(
                  (ivApplication) => ivApplication?.applicationId === applicationRef?.current?.id,
                );

                if (incomeVerificationRes?.status === 'CREATED') {
                  if (!isRelatedIVWithApplication) {
                    const res = await getBorrowerIncomeVerification();
                    const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
                    NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
                  } else {
                    const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
                    NavigateService.navigate(`/${slug}/${baseRoute}/${incomeVerificationRes?.id}`);
                  }
                } else if (incomeVerificationRes?.status === 'COMPLETE') {
                  if (!isRelatedIVWithApplication) {
                    const res = await getBorrowerIncomeVerification();
                    const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
                    NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
                  } else {
                    await pollApplicationStatus();
                  }
                } else if (incomeVerificationRes.status === 'REVIEW' || incomeVerificationRes.status === 'PENDING') {
                  if (!isRelatedIVWithApplication) {
                    const res = await getBorrowerIncomeVerification();
                    const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
                    NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
                  } else {
                    await pollApplicationStatus();
                  }
                } else {
                  await pollApplicationStatus();
                }
              } else {
                NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.current?.id}`);
              }
              break;
              // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            } catch (err: any) {
              if (err?.status === 404) {
                NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.current?.id}`);
              }
              break;
            }
          }
        default:
          await timeout(4000);
          await getApplicationStatus();
          break;
      }

      if (startPooling) {
        await timeout(4000);
        await getApplicationStatus?.();
      } else {
        return POLL_FINISHED;
      }
    } else {
      await timeout(4000);
      await getApplicationStatus?.();
    }
  };

  const IvCheck = async (applicationData?) => {
    const { sessionApiData } = useStore.getState();
    const { featureFlags, isCognitoCompleted } = sessionApiData || {};
    const { incomeVerification, application, authData } = useStore.getState() || {};

    const { slug } = authData || {};
    const currentApplication = applicationData || application;

    const incomeVerificationSession = {
      ...incomeVerification,
      errorMessage: incomeVerification?.errorMessage?.split(','),
      providerMessage: incomeVerification?.providerMessage?.split(','),
    };
    if (isCognitoCompleted) {
      pollApplicationStatus();
    } else if (
      incomeVerificationSession?.status &&
      incomeVerificationSession?.status === 'ERROR' &&
      incomeVerificationSession?.providerMessage?.includes('No_Valid_Account')
    ) {
      alert.info(translate('polling.error.noValidAccount'));
      if (currentApplication?.ivAllow?.paystub) {
        NavigateService.navigate(`/${slug}/upload-paystub/${currentApplication?.id}`);
      } else {
        NavigateService.navigate(`/${slug}/connect-bank-account/${currentApplication?.id}`);
      }
    } else if (
      incomeVerificationSession?.status &&
      !['COMPLETE', 'ERROR'].includes(incomeVerificationSession?.status)
    ) {
      NavigateService.navigate(`/${slug}/connect-bank-account/${currentApplication?.id}`);
    } else if (
      incomeVerificationSession?.status === 'ERROR' &&
      incomeVerificationSession?.errorMessage?.includes('Retry') &&
      incomeVerificationSession?.providerMessage?.includes('Institution_Found') &&
      featureFlags?.mxEnabled
    ) {
      NavigateService.navigate(`/${organization?.slug}/connect-bank-account/${currentApplication?.id}`);
    } else if (
      incomeVerificationSession?.status === 'ERROR' &&
      (incomeVerificationSession?.errorMessage?.includes('Institution_Not_Found') ||
        incomeVerificationSession?.errorMessage?.includes('Account_Not_Supported')) &&
      currentApplication?.ivAllow?.paystub
    ) {
      NavigateService.navigate(`/${slug}/upload-paystub/${currentApplication?.id}`);
    } else if (
      incomeVerificationSession?.status === 'ERROR' &&
      incomeVerificationSession?.errorMessage?.includes('Retry') &&
      currentApplication?.ivAllow?.paystub &&
      !featureFlags?.mxEnabled
    ) {
      NavigateService.navigate(`/${slug}/retry-plaid-connect/${currentApplication?.id}`);
    } else if (incomeVerificationSession?.status && incomeVerificationSession?.status === 'ERROR') {
      if (currentApplication?.ivAllow?.paystub) {
        NavigateService.navigate(`/${slug}/upload-paystub/${currentApplication?.id}`);
      } else {
        NavigateService.navigate(`/${slug}/connect-bank-account/${currentApplication?.id}`);
      }
    } else {
      try {
        const incomeVerificationSearchRes = await getBorrowerIncomeVerificationWithSearch(
          currentApplication?.borrowerId,
          currentApplication?.id,
        );
        if (incomeVerificationSearchRes?.contents?.length > 0) {
          const incomeVerificationRes = incomeVerificationSearchRes?.contents?.[0];
          setIncomeVerification(incomeVerificationRes);

          const isRelatedIVWithApplication = incomeVerificationRes?.relatedApplications?.find(
            (ivApplication) => ivApplication?.applicationId === currentApplication?.id,
          );

          if (incomeVerificationRes?.status === 'CREATED') {
            if (!isRelatedIVWithApplication) {
              const res = await getBorrowerIncomeVerification();
              const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
              NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
            } else {
              const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
              NavigateService.navigate(`/${slug}/${baseRoute}/${incomeVerificationRes?.id}`);
            }
          } else if (incomeVerificationRes?.status === 'COMPLETE') {
            if (!isRelatedIVWithApplication) {
              const res = await getBorrowerIncomeVerification();
              const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
              NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
            } else {
              await pollApplicationStatus();
            }
          } else if (incomeVerificationRes.status === 'REVIEW' || incomeVerificationRes.status === 'PENDING') {
            if (!isRelatedIVWithApplication) {
              const res = await getBorrowerIncomeVerification();
              const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
              NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
            } else {
              await pollApplicationStatus();
            }
          } else {
            await pollApplicationStatus();
          }
        } else {
          NavigateService.navigate(`/${slug}/connect-bank-account/${currentApplication?.id}`);
        }
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err?.status === 404) {
          NavigateService.navigate(`/${slug}/connect-bank-account/${currentApplication?.id}`);
        }
      }
    }
  };

  return {
    checkApplicationFlow,
    getBorrowerIncomeVerification,
    getApplicationStatus,
    getBorrowerIncomeVerificationWithSearch,
    getApplicationFlow,
    getApplication,
    IvCheck,
  };
};
